import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ApiRoute from "../../Router/ApiRouter";
import GSTR1Summary from "./Reports/gstr1";
import GSTR3BSummary from "./Reports/gstr3b";

const GstSummary = (props) => {
    const {guid} = useParams();
    const [gstSummary, setGstSummary] = React.useState(null);
    useEffect(() => {
        document.title = 'GST Summary'
        var apiRoute = new ApiRoute();
        apiRoute.postResponseFromRoute(`app_user_api_gst_filing_one_json`, {"guid": guid}).then((res) => {
            if(res.status === 'success'){
                console.log(res.data)
                setGstSummary(res.data)
            }
        }
        );
    }, [])
    if(gstSummary === null){
        return <div>Loading...</div>
    }
    return (
        <div>
        <h1>GST Summary</h1>
        {gstSummary.gstFilingReportMaster == "GSTR1" && <GSTR1Summary summary={gstSummary} />}
        {gstSummary.gstFilingReportMaster == "GSTR3B" && <GSTR3BSummary summary={gstSummary} />}
        </div>
    );
    }

export default GstSummary;