import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Divider from '@mui/material/Divider'
import { Link, Route, Routes } from 'react-router-dom'
import ImportContactsSharpIcon from '@mui/icons-material/ImportContactsSharp'
import {

  AccountBalance,
  AccountBalanceOutlined,
  AccountBox,
  BuildCircle,
  Dashboard,

  ExpandLess,
  ExpandMore,
  Inventory,
  Inventory2,
  Money,
  Note,
  Payment,
  Receipt,
  Report,
  Settings,
  ShoppingBasket,


} from '@mui/icons-material'
import { UilUniversity, UilInvoice, UilUserSquare, UilFileGraph } from '@iconscout/react-unicons'
import { Collapse, SvgIcon } from '@mui/material'
import { useThemeMode } from 'flowbite-react'
// import GstSummary from '../pages/gstFiling/gstSummary'
import GstSummary from '../pages/gstFiling/gstSummary'
const Home = React.lazy(() => import('../pages/dashboard'))
const UserItemIndex = React.lazy(() => import('../pages/userItems/index'))
const UserItemCreate = React.lazy(() => import('../pages/userItems/create'))
const UserItemEdit = React.lazy(() => import('../pages/userItems/edit'))
const Customerindex = React.lazy(() => import('../pages/customer/index'))
const CustomerCreate = React.lazy(() => import('../pages/customer/create'))
const Accounts = React.lazy(() => import('../pages/accounts/account'))
const AccountCreate = React.lazy(() => import('../pages/accounts/create'))
const Invoices = React.lazy(() => import('../pages/invoice/list'))
const InvoiceCreate = React.lazy(() => import('../pages/invoice/create'))
const AccountShow = React.lazy(() => import('../pages/accounts/show'));
const InvoiceShow = React.lazy(() => import('../pages/invoice/show'));
const PaymentRecieved = React.lazy(() => import('../pages/paymentRecieved/list'));
const PaymentRecievedCreate = React.lazy(() => import('../pages/paymentRecieved/create'));
const GstSetting = React.lazy(() => import('../pages/gstSetting/gstSetting'));
const Reports = React.lazy(() => import('../pages/reports/reports'))
// ReportBuilder
const ReportBuilder = React.lazy(() => import('../pages/reports/reportbuilder'))
const ExpenseList = React.lazy(() => import('../pages/expenses/list'))
const ExpenseCreate = React.lazy(() => import('../pages/expenses/create'))
const BankingIndex = React.lazy(() => import('../pages/banking/index'))
const BankingCreate = React.lazy(() => import('../pages/banking/create'))
const SettingIndex = React.lazy(() => import('../pages/settings/setting'))
const AccountSetting = React.lazy(() => import('../pages/settings/accountSetting'))
const InvoiceSetting = React.lazy(() => import('../pages/invoiceSetting/invoiceSetting'))
const PaymentSetting = React.lazy(() => import('../pages/paymentSetting/paymentSetting'))
const BillNew = React.lazy(() => import('../pages/bill/create'))
const BillList = React.lazy(() => import('../pages/bill/list'))
const BillShow = React.lazy(() => import('../pages/bill/show'))
const PaymentMadeIndex = React.lazy(() => import('../pages/paymentMade/index'))
const PaymentMadeCreate = React.lazy(() => import('../pages/paymentMade/create'))
const GstFiling = React.lazy(() => import('../pages/gstFiling/gstFiling'))
const CreditNoteIndex = React.lazy(() => import('../pages/crediNote/index'))
const CreditNoteCreate = React.lazy(() => import('../pages/crediNote/create'))
const CreditNoteSetting = React.lazy(() => import('../pages/settings/creditNoteSetting'))
const VendorIndex = React.lazy(() => import('../pages/vendor/index'))
const VendorCreate = React.lazy(() => import('../pages/vendor/create'))
const InventorySetting = React.lazy(() => import('../pages/settings/InventorySetting'))
const GstSummaryShow = React.lazy(() => import('../pages/gstFiling/InvoiceGstFilingWidget'))
const TaxRates = React.lazy(() => import('../pages/gstSetting/taxRates'))
const CustomerEdit = React.lazy(() => import('../pages/customer/edit'))
const VendorEdit = React.lazy(() => import('../pages/vendor/edit'))
const ItemSetting = React.lazy(() => import('../pages/settings/itemSetting'))
const ItemShow = React.lazy(() => import('../pages/userItems/show.jsx'))

// journals

const ManualJournals = React.lazy(() => import('../pages/manualJournals/index'))
const ManualJournalsCreate = React.lazy(() => import('../pages/manualJournals/create'))
// sale order
const SaleOrderCreate  = React.lazy(() => import('../pages/saleOrder/create'))
const SaleOrderIndex = React.lazy(()=> import('../pages/saleOrder/index'))
function AccessControlList() {
  return [
    {
      label: 'Dashboard',
      menu: true,
      link: '/dashboard',
      icon: <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color={"#4a90e2"} fill={"none"}>
          <circle cx="6.25" cy="6.25" r="4.25" stroke="currentColor" strokeWidth="1.5" />
          <circle cx="17.75" cy="17.75" r="4.25" stroke="currentColor" strokeWidth="1.5" />
          <circle cx="6.25" cy="17.75" r="4.25" stroke="currentColor" strokeWidth="1.5" />
          <path d="M18 2V10M22 6L14 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </SvgIcon>,
      component: Home,
      access: 'ROLE_USER',

    },
    {
      label: 'Credit Note',
      menu: true,
      link: '/sale-order',
      icon: <Note />,
      component: SaleOrderIndex,
      access: 'ROLE_USER',

    },
    {
      label: 'Banking',
      menu: true,
      link: '/banking',
      icon: <AccountBalanceOutlined htmlColor='#4a90e2' />,
      component: BankingIndex,
      access: 'ROLE_USER',

    },

    {
      label: 'Banking',
      menu: false,
      link: '/banking-new',
      icon: <UilUniversity />,
      component: BankingCreate,
      access: 'ROLE_USER',

    },
    {
      label: 'Invoices',
      menu: false,
      link: '/invoices',
      icon: <BuildCircle />,
      component: Invoices,
      access: 'ROLE_USER',

    },
    {
      label: 'Invoices',
      menu: false,
      link: '/credit-notes',
      icon: <BuildCircle />,
      component: CreditNoteIndex,
      access: 'ROLE_USER',

    },


    {
      label: 'Bill New',
      menu: false,
      link: '/bill-new',
      icon: <UilUniversity />,
      component: BillNew,
      access: 'ROLE_USER',

    },
    {
      label: 'Items',
      menu: true,
      link: '/user-items',
      icon: <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color={"#4a90e2"} fill={"none"}>
          <path d="M3.06164 14.4413L3.42688 12.2985C3.85856 9.76583 4.0744 8.49951 4.92914 7.74975C5.78389 7 7.01171 7 9.46734 7H14.5327C16.9883 7 18.2161 7 19.0709 7.74975C19.9256 8.49951 20.1414 9.76583 20.5731 12.2985L20.9384 14.4413C21.5357 17.946 21.8344 19.6983 20.9147 20.8491C19.995 22 18.2959 22 14.8979 22H9.1021C5.70406 22 4.00504 22 3.08533 20.8491C2.16562 19.6983 2.4643 17.946 3.06164 14.4413Z" stroke="currentColor" strokeWidth="1.5" />
          <path d="M7.5 9L7.71501 5.98983C7.87559 3.74176 9.7462 2 12 2C14.2538 2 16.1244 3.74176 16.285 5.98983L16.5 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      </SvgIcon>,
      component: UserItemIndex,
      access: 'ROLE_USER',

    },
    {
      label: 'Customer',
      menu: true,
      link: '/customers',
      icon: <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color={"#4a90e2"} fill={"none"}>
          <path d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z" stroke="currentColor" strokeWidth="1.5" />
        </svg>
      </SvgIcon>,
      component: Customerindex,
      access: 'ROLE_USER',

    },
    {
      label: 'Vendors',
      menu: true,
      link: '/vendors',
      icon: <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color={"#4a90e2"} fill={"none"}>
          <path d="M3 7H6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M3 17H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18 17L21 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15 7L21 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6 7C6 6.06812 6 5.60218 6.15224 5.23463C6.35523 4.74458 6.74458 4.35523 7.23463 4.15224C7.60218 4 8.06812 4 9 4C9.93188 4 10.3978 4 10.7654 4.15224C11.2554 4.35523 11.6448 4.74458 11.8478 5.23463C12 5.60218 12 6.06812 12 7C12 7.93188 12 8.39782 11.8478 8.76537C11.6448 9.25542 11.2554 9.64477 10.7654 9.84776C10.3978 10 9.93188 10 9 10C8.06812 10 7.60218 10 7.23463 9.84776C6.74458 9.64477 6.35523 9.25542 6.15224 8.76537C6 8.39782 6 7.93188 6 7Z" stroke="currentColor" strokeWidth="1.5" />
          <path d="M12 17C12 16.0681 12 15.6022 12.1522 15.2346C12.3552 14.7446 12.7446 14.3552 13.2346 14.1522C13.6022 14 14.0681 14 15 14C15.9319 14 16.3978 14 16.7654 14.1522C17.2554 14.3552 17.6448 14.7446 17.8478 15.2346C18 15.6022 18 16.0681 18 17C18 17.9319 18 18.3978 17.8478 18.7654C17.6448 19.2554 17.2554 19.6448 16.7654 19.8478C16.3978 20 15.9319 20 15 20C14.0681 20 13.6022 20 13.2346 19.8478C12.7446 19.6448 12.3552 19.2554 12.1522 18.7654C12 18.3978 12 17.9319 12 17Z" stroke="currentColor" strokeWidth="1.5" />
        </svg>
      </SvgIcon>,
      component: VendorIndex,
      access: 'ROLE_USER',

    },
    {
      label: 'Vendor Create',
      menu: false,
      link: '/vendor-create',

      component: VendorCreate,
      access: 'ROLE_USER',

    },
    {
      label: 'Vendor Edit',
      menu: false,
      link: '/vendor-edit/:id',

      component: VendorEdit,
      access: 'ROLE_USER',

    },
    {
      label: 'Credit Note Create',
      menu: false,
      link: '/credit-note-create',
      icon: <Note />,
      component: CreditNoteCreate,
      access: 'ROLE_USER',

    },
    {
      label: 'Item Setting',
      menu: false,
      link: '/inventory-setting',
      icon: <Note />,
      component: InventorySetting,
      access: 'ROLE_USER',

    },
    {
      label: 'Item Setting',
      menu: false,
      link: '/item-setting',
      icon: <Note />,
      component: ItemSetting,
      access: 'ROLE_USER',

    },
    {
      label: 'Manual Journals INdex',
      menu: false,
      link: '/manual-journals',
      icon: <Dashboard />,
      component: ManualJournals,
      access: 'ROLE_USER',
    },
    {
      label: 'Manual Journals INdex',
      menu: false,
      link: '/manual-journal-create',
      icon: <Dashboard />,
      component: ManualJournalsCreate,
      access: 'ROLE_USER',
    },
    {
      label: 'Sale Order',
      menu: false,
      link: '/sale-order-create',
      icon: <Note />,
      component: SaleOrderCreate,
      access: 'ROLE_USER',

    },
    {
      label: 'Sales',
      menu: true,
      icon: <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color={"#4a90e2"} fill={"none"}>
          <path d="M8 16H15.2632C19.7508 16 20.4333 13.1808 21.261 9.06908C21.4998 7.88311 21.6192 7.29013 21.3321 6.89507C21.045 6.5 20.4947 6.5 19.3941 6.5H6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M8 16L5.37873 3.51493C5.15615 2.62459 4.35618 2 3.43845 2H2.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M8.88 16H8.46857C7.10522 16 6 17.1513 6 18.5714C6 18.8081 6.1842 19 6.41143 19H17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <circle cx="10.5" cy="20.5" r="1.5" stroke="currentColor" strokeWidth="1.5" />
          <circle cx="17.5" cy="20.5" r="1.5" stroke="currentColor" strokeWidth="1.5" />
        </svg>
      </SvgIcon>,
      access: 'ROLE_USER',
      child: [

        {
          label: 'Invoices',
          menu: true,
          link: '/invoices',
          icon: <BuildCircle />,
          component: Invoices,
          access: 'ROLE_USER',

        },
        {
          label: 'Credit Note',
          menu: true,
          link: '/credit-notes',
          icon: <Note />,
          component: CreditNoteIndex,
          access: 'ROLE_USER',

        },
        {
          label: 'Sale Order',
          menu: true,
          link: '/sale-order',
          icon: <Note />,
          component: SaleOrderIndex,
          access: 'ROLE_USER',

        },
        
        

        {
          label: 'Payment Recieved',
          menu: true,
          link: '/payment-recieved',
          icon: <Money />,
          component: PaymentRecieved,
          access: 'ROLE_USER',
        },

      ]

    },
   
    {
      label: 'Purchase',
      menu: true,
      icon: <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color={"#4a90e2"} fill={"none"}>
          <path d="M11.5 8H20.196C20.8208 8 21.1332 8 21.3619 8.10084C22.3736 8.5469 21.9213 9.67075 21.7511 10.4784C21.7205 10.6235 21.621 10.747 21.4816 10.8132C21.1491 10.971 20.8738 11.2102 20.6797 11.5M7.5 8H3.80397C3.17922 8 2.86684 8 2.63812 8.10084C1.6264 8.5469 2.07874 9.67075 2.24894 10.4784C2.27952 10.6235 2.37896 10.747 2.51841 10.8132C3.09673 11.0876 3.50177 11.6081 3.60807 12.2134L4.20066 15.5878C4.46138 17.0725 4.55052 19.1942 5.8516 20.2402C6.8062 21 8.18162 21 10.9325 21H13.0675C13.2156 21 13.5 20.9999 13.5 20.9999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M16 16.5C16.4915 15.9943 17.7998 14 18.5 14M21 16.5C20.5085 15.9943 19.2002 14 18.5 14M18.5 14V21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.5 11L10 3M15 3L17.5 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      </SvgIcon>,
      access: 'ROLE_USER',
      child: [
        {
          label: 'Purchase Bills',
          menu: true,
          link: '/purchase-bills',
          icon: <Receipt />,
          component: BillList,
          access: 'ROLE_USER',
        },
        {
          label: 'Payment Made',
          menu: true,
          link: '/payment-made',
          icon: <Money />,
          component: PaymentMadeIndex,
          access: 'ROLE_USER',
        },
        {
          label: 'Expenses',
          menu: true,
          link: '/expenses',
          icon: <Dashboard />,
          component: ExpenseList,
          access: 'ROLE_USER',
        },
      ]
    },
    {
      label: 'Accountant',
      menu: true,
      icon: <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color={"#4a90e2"} fill={"none"}>
          <path d="M12.5 22H6.59087C5.04549 22 3.81631 21.248 2.71266 20.1966C0.453365 18.0441 4.1628 16.324 5.57757 15.4816C7.827 14.1422 10.4865 13.7109 13 14.1878" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15.5 6.5C15.5 8.98528 13.4853 11 11 11C8.51472 11 6.5 8.98528 6.5 6.5C6.5 4.01472 8.51472 2 11 2C13.4853 2 15.5 4.01472 15.5 6.5Z" stroke="currentColor" strokeWidth="1.5" />
          <path d="M18.6911 14.5777L19.395 15.9972C19.491 16.1947 19.7469 16.3843 19.9629 16.4206L21.2388 16.6343C22.0547 16.7714 22.2467 17.3682 21.6587 17.957L20.6668 18.9571C20.4989 19.1265 20.4069 19.4531 20.4589 19.687L20.7428 20.925C20.9668 21.9049 20.4509 22.284 19.591 21.7718L18.3951 21.0581C18.1791 20.929 17.8232 20.929 17.6032 21.0581L16.4073 21.7718C15.5514 22.284 15.0315 21.9009 15.2554 20.925L15.5394 19.687C15.5914 19.4531 15.4994 19.1265 15.3314 18.9571L14.3395 17.957C13.7556 17.3682 13.9436 16.7714 14.7595 16.6343L16.0353 16.4206C16.2473 16.3843 16.5033 16.1947 16.5993 15.9972L17.3032 14.5777C17.6872 13.8074 18.3111 13.8074 18.6911 14.5777Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </SvgIcon>,
      access: 'ROLE_USER',
      child: [
        {
          label: 'Manual Journals',
          menu: true,
          link: '/manual-journals',
          icon: <Receipt />,
          component: ManualJournals,
          access: 'ROLE_USER',
        }
      ]
    },
    {
      label: 'User Item Create',
      menu: false,
      link: '/user-item-create',
      icon: <Dashboard />,
      component: UserItemCreate,
      access: 'ROLE_USER',

    },
    {
      label: 'User Item Show',
      menu: false,
      link: '/user-item-show/:id',
      icon: <Dashboard />,
      component: ItemShow,
      access: 'ROLE_USER',

    },
    {
      label: 'PaymentMade Create',
      menu: false,
      link: '/payment-made-create',
      icon: <Dashboard />,
      component: PaymentMadeCreate,
      access: 'ROLE_USER',

    },
    {
      label: 'User Item Edit',
      menu: false,
      link: '/user-item-edit/:id',
      icon: <Dashboard />,
      component: UserItemEdit,
      access: 'ROLE_USER',

    },

    {
      label: 'Customer Create',
      menu: false,
      link: '/customer-create',
      icon: <Dashboard />,
      component: CustomerCreate,
      access: 'ROLE_USER',

    },
    {
      label: 'Ledgers',
      menu: true,
      link: '/accounts',
      icon: <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color={"#4a90e2"} fill={"none"}>
          <path d="M12 2C17.5237 2 22 6.47778 22 12C22 17.5222 17.5237 22 12 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9 21.5C7.81163 21.0953 6.69532 20.5107 5.72302 19.7462M5.72302 4.25385C6.69532 3.50059 7.81163 2.90473 9 2.5M2 10.2461C2.21607 9.08813 2.66019 7.96386 3.29638 6.94078M2 13.7539C2.21607 14.9119 2.66019 16.0361 3.29638 17.0592" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8 16.5C10.0726 14.302 13.9051 14.1986 16 16.5M14.2179 9.75C14.2179 10.9926 13.2215 12 11.9925 12C10.7634 12 9.76708 10.9926 9.76708 9.75C9.76708 8.50736 10.7634 7.5 11.9925 7.5C13.2215 7.5 14.2179 8.50736 14.2179 9.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      </SvgIcon>,
      component: Accounts,
      access: 'ROLE_USER',

    },

    {
      label: 'Purchase Bills',
      menu: false,
      link: '/purchase-bills',
      icon: <Receipt />,
      component: BillList,
      access: 'ROLE_USER',
    },
    {
      label: 'Bill Show',
      menu: false,
      link: '/bill-show/:id',
      icon: <Receipt />,
      component: BillShow,
      access: 'ROLE_USER',
    },
    {
      label: 'Payment Recieved',
      menu: false,
      link: '/payment-recieved',
      icon: <Money />,
      component: PaymentRecieved,
      access: 'ROLE_USER',
    },
    {
      label: 'Payment Made',
      menu: false,
      link: '/payment-made',
      icon: <Money />,
      component: PaymentMadeIndex,
      access: 'ROLE_USER',
    },
    {
      label: 'Payment Recieved',
      menu: false,
      link: '/payment-recieved-create',
      icon: <Inventory2 />,
      component: PaymentRecievedCreate,
      access: 'ROLE_USER',
    },

    {
      label: 'Invoice Create',
      menu: false,
      link: '/invoice-create',
      icon: <Inventory />,
      component: InvoiceCreate,
      access: 'ROLE_USER',

    },

    {
      label: 'Account Create',
      menu: false,
      link: '/account-create',
      icon: <Dashboard />,
      component: AccountCreate,
      access: 'ROLE_USER',

    },
    {
      label: 'Account Show',
      menu: false,
      link: '/account-show/:id',
      icon: <Dashboard />,
      component: AccountShow,
      access: 'ROLE_USER',
    },
    {
      label: 'Invoice Show',
      menu: false,
      link: '/invoice-show/:id',
      icon: <Dashboard />,
      component: InvoiceShow,
      access: 'ROLE_USER',
    },
    {
      label: 'GST Setting',
      menu: false,
      link: '/settings/gst',
      icon: <Settings />,
      component: GstSetting,
      access: 'ROLE_USER',

    },
    {
      label: 'GST Setting',
      menu: false,
      link: '/settings/tax-rates',
      icon: <Settings />,
      component: TaxRates,
      access: 'ROLE_USER',

    },
    {
      label: 'Customer Edit',
      menu: false,
      link: '/customer-edit/:id',
      icon: <Settings />,
      component: CustomerEdit,
      access: 'ROLE_USER',

    },

    {
      label: 'Account Setting',
      menu: false,
      link: '/settings/account-setting',
      icon: <Settings />,
      component: AccountSetting,
      access: 'ROLE_USER',

    },
    {
      label: 'Reports',
      menu: true,
      link: '/reports',
      icon: <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color={"#4a90e2"} fill={"none"}>
          <path d="M21 21H10C6.70017 21 5.05025 21 4.02513 19.9749C3 18.9497 3 17.2998 3 14V3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M5 20C5.43938 16.8438 7.67642 8.7643 10.4282 8.7643C12.3301 8.7643 12.8226 12.6353 14.6864 12.6353C17.8931 12.6353 17.4282 4 21 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </SvgIcon>,
      component: Reports,
      access: 'ROLE_USER',
    },
    {
      label: 'Report Builder',
      menu: false,
      link: '/report/:reportName',
      icon: <Dashboard />,
      component: ReportBuilder,
      access: 'ROLE_USER',
    },
    {
      label: 'Expenses',
      menu: false,
      link: '/expenses',
      icon: <Dashboard />,
      component: ExpenseList,
      access: 'ROLE_USER',
    },
    {
      label: 'Expenses',
      menu: false,
      link: '/expense-create',
      icon: <Dashboard />,
      component: ExpenseCreate,
      access: 'ROLE_USER',
    },
    {
      label: 'Settings',
      menu: false,
      link: '/settings',
      icon: <Settings />,
      component: SettingIndex,
      access: 'ROLE_USER',
    },
    {
      label: 'Invoice Setting',
      menu: false,
      link: '/setting/invoice-setting',
      icon: <Settings />,
      component: InvoiceSetting,
      access: 'ROLE_USER',
    },
    {
      label: 'Payment Setting',
      menu: false,
      link: '/setting/payment-setting',
      icon: <Settings />,
      component: PaymentSetting,
      access: 'ROLE_USER',
    },
    {
      label: 'GST Filing',
      menu: true,
      gst: true,
      link: '/setting/gst-filing',
      icon: <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color={"#4a90e2"} fill={"none"}>
          <path d="M4 20L20 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M8.26777 4.73223C9.24408 5.70854 9.24408 7.29146 8.26777 8.26777C7.29146 9.24408 5.70854 9.24408 4.73223 8.26777C3.75592 7.29146 3.75592 5.70854 4.73223 4.73223C5.70854 3.75592 7.29146 3.75592 8.26777 4.73223Z" stroke="currentColor" strokeWidth="1.5" />
          <path d="M19.2678 15.7322C20.2441 16.7085 20.2441 18.2915 19.2678 19.2678C18.2915 20.2441 16.7085 20.2441 15.7322 19.2678C14.7559 18.2915 14.7559 16.7085 15.7322 15.7322C16.7085 14.7559 18.2915 14.7559 19.2678 15.7322Z" stroke="currentColor" strokeWidth="1.5" />
        </svg>
      </SvgIcon>,
      component: GstFiling,
      access: 'ROLE_USER',
    },
    {
      label: 'Credit Note Setting',
      menu: false,
      link: '/setting/credit-note-setting',
      icon: <Settings />,
      component: CreditNoteSetting,
      access: 'ROLE_USER',
    },
    {
      label: 'GST Summary',
      menu: false,
      gst: true,
      link: '/setting/gst-summary/:guid',
      icon: <Settings />,
      component: GstSummary,
      access: 'ROLE_USER',
    },
    {
      label: 'GST Summary',
      menu: false,
      gst: true,
      link: '/reports/gst-summary-detail/:guid',
      icon: <Settings />,
      component: GstSummaryShow,
      access: 'ROLE_USER',
    },


  ]
}

export function AccessControlledMenu(props) {
  const rootUserInfo = JSON.parse(localStorage.getItem('user'))
  const gstSettingInfo = useSelector(state => state.gstSetting.value)

  if (rootUserInfo) {
    return (
      <>
        {AccessControlList().map((item, index) => {
          if (Array.isArray(item.access)) {
            var show = false;
            item.access.forEach((access) => {
              if (rootUserInfo.roles.includes(access)) {
                if (item.menu) {
                  show = true;
                }
              }
            });
            console.log(show)
            if (show == true) {
              return <AccessControlledListItem item={item} />
            }
          } else {
            if (rootUserInfo.roles.includes(item.access)) {
              if (item.menu && gstSettingInfo?.enabled === item.gst) {

                return <AccessControlledListItem item={item} />
              } else if (item.menu && !item.gst) {
                return <AccessControlledListItem item={item} />
              }
            }
          }
        })}
      </>
    )
  }
  return <></>
}
function AccessControlledListItem({ item }) {
  const rootUserInfo = JSON.parse(localStorage.getItem('user'))

  const [openCollapse, setOpenCollapse] = React.useState(false)
  const handleOpenSettings = () => {
    setOpenCollapse(!openCollapse)
  }
  const theme = useThemeMode()
  if (rootUserInfo) {
    return (
      <>
        {item.child == null ? (
          <ListItem button component={Link} to={item.link} key={item.label} style={{ height: 40 }}>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.label} />
            {/* {openCollapse ? <ExpandLess /> : <ExpandMore />} */}
          </ListItem>
        ) : (
          <>
            <ListItem button key={item.label} onClick={handleOpenSettings}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
              {openCollapse ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.child.map((citem, index) => {
                  if (rootUserInfo.roles.includes(citem.access)) {
                    return (
                      <ListItemButton button component={Link} to={citem.link} style={{ height: 35 }} >
                        <ListItemIcon sx={{}} >

                        </ListItemIcon>
                        <ListItemText primary={citem.label} />
                      </ListItemButton>
                    )
                  }
                })}
              </List>
            </Collapse>
            <Divider />
          </>
        )}
      </>
    )
  }
}
export function AccessControlledRouterLinks(props) {
  const user = JSON.parse(localStorage.getItem('user'))
  console.log(user)
  if (user) {
    return (
      <>
        {AccessControlList().map((item, index) => {
          if (item.child == null) {
            if (Array.isArray(item.access)) {
              var show = false;
              item.access.forEach((access) => {
                if (user.roles.includes(access)) {
                  show = true;
                }
              });
              if (show == true) {
                return (
                  <Route exact path={item.link} element={<item.component />} />


                )
              }
            } else {
              if (user.roles.includes(item.access)) {
                return (
                  <Route exact path={item.link} element={<item.component />} />

                )
              }
            }
          } else {
            {
              item.child != null && item.child.map((childItem, childIndex) => {
                // if (rootUserInfo.roles.includes(childItem.access)) {
                return (
                  <Route exact path={childItem.link}>
                    <childItem.component />
                  </Route>
                )
                // }
              })
            }
            // return <AccessControlledRouterChildLinks child={item.child} />
          }
        })}

        {/* <Route path='/' element={<Home/>}></Route> */}
      </>
    )
  }
  return <></>
}
function AccessControlledRouterChildLinks({ child }) {
  const rootUserInfo = JSON.parse(localStorage.getItem('user'))
  if (rootUserInfo) {
    return (
      <>
        {child.map((childItem, childIndex) => {
          if (rootUserInfo.roles.includes(childItem.access)) {
            return (
              <Route exact path={childItem.link}>
                <childItem.component />
              </Route>
            )
          }
        })}
      </>
    )
  }
}
