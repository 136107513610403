import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AccessControlledMenu } from "./accessControlledMenu";
import { MoreVert, PowerSettingsNew, Settings, SettingsAccessibility } from "@mui/icons-material";
import { ButtonGroup, Menu, MenuItem } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, DarkThemeToggle, Dropdown, useThemeMode } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import logo from '../images/cypee_logo.png'
// const l;
const drawerWidth = 250;





function MyDrawer(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const theme = useThemeMode();
    const [backgroundColor, setBackgroundColor] = useState('white');
    const [color, setColor] = useState('black');
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    React.useEffect(() => {
        if (theme.mode == 'dark') {
            setBackgroundColor('#181d1f')
            setColor('white')
        } else {
            setBackgroundColor('white')
            setColor('black')
        }
    }, [theme])
    const drawer = (
        <div className="" style={{ height: '100%', backgroundColor: backgroundColor, color: color }}
        >
            <div className="drawer-image">
                <img src={logo} style={{ height: '40px' }} />
            </div>
            <Divider />
            <UserInfo />
            <Divider />
            <List>
                <AccessControlledMenu />
            </List>
        </div>
    );
    const container = window !== undefined ? () => window().document.body : undefined;
    const dispatch = useDispatch();
    return (
        <div style={{

        }}>
            <AppBar
                position="fixed"
                style={{ backgroundColor: backgroundColor, color: color }}
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        User Panel
                    </Typography>
                    <Button onClick={() => {
                        navigate('/settings')
                    }} ><Settings htmlColor={`${theme.mode == 'light' ? '#000' : '#fff'}`} /></Button>
                    <DarkThemeToggle onChangeCapture={() => {


                    }} />


                    <Button color="error" onClick={() => {
                        if (localStorage.getItem('user')) {
                            localStorage.removeItem('user')
                            navigate(0)
                        }
                    }} disableElevation={true}><PowerSettingsNew /></Button>
                    <Avatar onClick={() => {

                    }} placeholderInitials={JSON.parse(localStorage.getItem('user'))?.name?.substring(0, 1)} rounded />

                </Toolbar>
            </AppBar>
            <Box

                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer

                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </div>
    );
}

MyDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};
export default MyDrawer;
function UserInfo(props) {
    const [user, setUser] = React.useState(null);

    // console.log(rootUserInfo);

    React.useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')));
    }, []);
    return (<div className="drawer-user-info">

        <span className="user-info">{user ? user.name : ""}</span>
    </div>);
}

