import React, { useEffect } from "react";
import ApiRoute from "../../../Router/ApiRouter";
import { Link } from "react-router-dom";

const GSTR1Summary = (props) => {
    const [gstr, setGstr] = React.useState(null);
    useEffect(() => {
        // document.title = 'GSTR1 Summary'
        // app_user_api_gstr1_json
        var apiRoute = new ApiRoute();
        apiRoute.postResponseFromRoute(`app_user_api_gstr1_json`, { "guid": props.summary.guid }).then((res) => {
            if (res.status === 'success') {
                console.log(res.data)
                setGstr(res.data)
            }
        }
        );

    }, []);
    if (gstr === null) {
        return <div>Loading...</div>
    }
    return (
        <div>
            <table className="table">
                <thead>
                    <th>Description</th>
                    <th>Total Invoices</th>
                    <th>Total Value</th>
                    <th>Total IGST</th>
                    <th>Total CGST</th>
                    <th>Total SGST</th>
                    <th>Total CESS</th>
                </thead>
                <tbody>
                    <tr>
                        <td><Link  to={`/reports/gst-summary-detail/${props.summary.guid}?type=b2b`}>B2B</Link></td>
                        <td>{gstr["b2b"]["inv_summary"]["totalInvoices"]}</td>
                        <td>{gstr["b2b"]["inv_summary"]["txval"]}</td>
                        <td>{gstr["b2b"]["inv_summary"]["iamt"]}</td>
                        <td>{gstr["b2b"]["inv_summary"]["camt"]}</td>
                        <td>{gstr["b2b"]["inv_summary"]["samt"]}</td>
                        <td>0.00</td>
                    </tr>
                    <tr>
                        <td><Link  to={`/reports/gst-summary-detail/${props.summary.guid}?type=b2cl`}>B2C (Large)</Link></td>
                        <td>{gstr["b2cl"]["inv_summary"]["totalInvoices"]}</td>
                        <td>{gstr["b2cl"]["inv_summary"]["txval"]}</td>
                        <td>{gstr["b2cl"]["inv_summary"]["iamt"]}</td>
                        <td>{gstr["b2cl"]["inv_summary"]["camt"]}</td>
                        <td>{gstr["b2cl"]["inv_summary"]["samt"]}</td>
                        <td>0.00</td>
                    </tr>
                    {/* B2CS */}
                    <tr>
                        <td><Link  to={`/reports/gst-summary-detail/${props.summary.guid}?type=b2cs`}>B2C (Small)</Link></td>
                        <td>{gstr["b2cs"]["inv_summary"]["totalInvoices"]}</td>
                        <td>{gstr["b2cs"]["inv_summary"]["txval"]}</td>
                        <td>{gstr["b2cs"]["inv_summary"]["iamt"]}</td>
                        <td>{gstr["b2cs"]["inv_summary"]["camt"]}</td>
                        <td>{gstr["b2cs"]["inv_summary"]["samt"]}</td>
                        <td>0.00</td>
                    </tr>
                    {/* Advance */}
                    <tr>
                        <td><Link to={`/reports/gst-summary-detail/${props.summary.guid}?type=at`}>Advance</Link></td>
                        <td>{gstr["at"]["inv_summary"]["totalInvoices"]}</td>
                        <td>{gstr["at"]["inv_summary"]["txval"]}</td>
                        <td>{gstr["at"]["inv_summary"]["iamt"]}</td>
                        <td>{gstr["at"]["inv_summary"]["camt"]}</td>
                        <td>{gstr["at"]["inv_summary"]["samt"]}</td>
                        <td>0.00</td>
                    </tr>
                    {/* HSN */}
                    <tr>
                        <td><Link to={`/reports/gst-summary-detail/${props.summary.guid}?type=hsn`}>HSN</Link></td>
                        <td>{gstr["hsn"]["hsn_summary"]["num"]}</td>
                        <td>{gstr["hsn"]["hsn_summary"]["txval"]}</td>
                        <td>{gstr["hsn"]["hsn_summary"]["iamt"]}</td>
                        <td>{gstr["hsn"]["hsn_summary"]["camt"]}</td>
                        <td>{gstr["hsn"]["hsn_summary"]["samt"]}</td>
                        <td>0.00</td>
                    </tr>
                    {/* cdnr */}
                    <tr>
                        <td><Link to={`/reports/gst-summary-detail/${props.summary.guid}?type=cdnr`}>Credit Note</Link></td>
                        <td>{gstr["cdnr"]["inv_summary"]["totalInvoices"]}</td>
                        <td>{gstr["cdnr"]["inv_summary"]["txval"]}</td>
                        <td>{gstr["cdnr"]["inv_summary"]["iamt"]}</td>
                        <td>{gstr["cdnr"]["inv_summary"]["camt"]}</td>
                        <td>{gstr["cdnr"]["inv_summary"]["samt"]}</td>
                        <td>0.00</td>
                    </tr>
                    {/* exmpt */}
                    <tr>
                        <td><Link to={`/reports/gst-summary-detail/${props.summary.guid}?type=exempt`}>Exempt</Link></td>
                        <td>{gstr["nil"]["inv_summary"]["totalInvoices"]}</td>
                        <td>{gstr["nil"]["inv_summary"]["txval"]}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
export default GSTR1Summary;