import React, { useEffect } from "react";
import ApiRoute from "../../../Router/ApiRouter";
import { Link } from "react-router-dom";

const GSTR3BSummary = (props) => {
    const [gstr, setGstr] = React.useState(null);
    useEffect(() => {
        // document.title = 'GSTR1 Summary'
        // app_user_api_gstr3b_json
        var apiRoute = new ApiRoute();
        apiRoute.postResponseFromRoute(`app_user_api_gstr3b_json`, { "guid": props.summary.guid }).then((res) => {
            if (res.status === 'success') {
                console.log(res.data)
                setGstr(res.data)
            }
        }
        );

    }, []);
    if (gstr === null) {
        return <div>Loading...</div>
    }
    return (<>
        {/* 1 and 2 */}
        <table className="table">
            <tr>
                <th>GSTIN</th>
                <td>{gstr["gstin"]}</td>
            </tr>
             {/* 2 left */}
        </table>

        {/* table 3.1  */}
        <b>3.1 Details of Outward Supplies and inward supplies liable to reverse charge.</b>
        <table className="table">
            <thead>
                <tr>
                    <th>Nature of Supply</th>
                    <th>Taxable Value</th>
                    <th>Integrated Tax</th>
                    <th>Central Tax</th>
                    <th>State/UT Tax</th>
                    <th>Cess</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>(a) Outward taxable supplies (other than zero rated, nil rated and exempted)</td>
                        <td>{gstr["sup_details"]["osup_det"]["inv_summary"]["txval"]}</td>
                        <td>{gstr["sup_details"]["osup_det"]["inv_summary"]["iamt"]}</td>
                        <td>{gstr["sup_details"]["osup_det"]["inv_summary"]["camt"]}</td>
                        <td>{gstr["sup_details"]["osup_det"]["inv_summary"]["samt"]}</td>
                        <td>{gstr["sup_details"]["osup_det"]["inv_summary"]["csamt"]}</td>
                    </tr>
                    {/* osup_nil_exmp/Other outward supplies (Nil rated, exempted) */}
                    <tr>
                        <td>(c) Other outward supplies (Nil rated, exempted)</td>
                        <td>{gstr["sup_details"]["osup_nil_exmp"]["inv_summary"]["txval"]}</td>
                        <td>{gstr["sup_details"]["osup_nil_exmp"]["inv_summary"]["iamt"]}</td>
                        <td>{gstr["sup_details"]["osup_nil_exmp"]["inv_summary"]["camt"]}</td>
                        <td>{gstr["sup_details"]["osup_nil_exmp"]["inv_summary"]["samt"]}</td>
                        <td>{gstr["sup_details"]["osup_nil_exmp"]["inv_summary"]["csamt"]}</td>
                    </tr>
                    {/* isup_rev/Inward supplies (liable to reverse charge) */}
                    <tr>
                        <td>(d) Inward supplies (liable to reverse charge)</td>
                        <td>{gstr["sup_details"]["isup_rev"]["inv_summary"]["txval"]}</td>
                        <td>{gstr["sup_details"]["isup_rev"]["inv_summary"]["iamt"]}</td>
                        <td>{gstr["sup_details"]["isup_rev"]["inv_summary"]["camt"]}</td>
                        <td>{gstr["sup_details"]["isup_rev"]["inv_summary"]["samt"]}</td>
                        <td>{gstr["sup_details"]["isup_rev"]["inv_summary"]["csamt"]}</td>
                    </tr>
                </tbody>
        </table>
    </>);
}

export default GSTR3BSummary;