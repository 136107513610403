import { configureStore } from '@reduxjs/toolkit';
import userInfoReducer from './slices/UserInfoSlice';
import { themeSlice } from './slices/ThemeSlice';
import gstSettingSlice from './slices/gstSettingSlice';
export default configureStore({
  reducer: {
   
    rootUserInfo: userInfoReducer,
    theme: themeSlice.reducer,
    gstSetting: gstSettingSlice
  }
})