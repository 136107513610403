import React, { useEffect } from "react";
import ApiRoute from "../Router/ApiRouter";
import { useNavigate } from "react-router-dom";
import { Card } from "flowbite-react";
import { toast } from "react-toastify";

export default function Dashboard() {
    const navigate = useNavigate();
    const [stats, setStats] = React.useState(null);
    useEffect(() => {
        var apiRoute = new ApiRoute();
        apiRoute.postResponseFromRoute('app_user_api_dashboard', {}).then((response) => {
            setStats(response.data)
        })

    }, []);

    return (<>
        <nav class="mt-5 flex mb-4" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3 rtl:space-x-reverse">
                <li class="inline-flex items-center">
                    <a onClick={() => { navigate('/dashboard') }} class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                        <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                        </svg>
                        Dashboard
                    </a>
                </li>



            </ol>
        </nav>
        <h1>Dashboard</h1>
        <div class="row">
            <div class="col-md-4">
                <div class="statcard bg-info p-4">
                    <h2 class="statcard-number">
                        {stats?.total_invoice}
                    </h2>
                    <span class="statcard-desc">Total Invoices</span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="statcard bg-info p-4">
                    <h2 class="statcard-number">
                        {stats?.total_income}
                    </h2>
                    <span class="statcard-desc">Total Payment Recieved</span>
                </div>
            </div>
        </div>
    </>)
}