import logo from './logo.svg';
import './App.css';
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import React from 'react';
import Login from './pages/login'
import { Box, CssBaseline, CircularProgress, Container, } from '@mui/material'
import MyDrawer from './components/drawer'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AccessControlledRouterLinks } from './components/accessControlledMenu';
import Dashboard from './pages/dashboard';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useThemeMode } from 'flowbite-react';
import { setTheme } from 'flowbite-react/lib/esm/theme-store';
import { setGstSetting } from './reduxStores/slices/gstSettingSlice';
import { useDispatch } from 'react-redux';
import ApiRoute from './Router/ApiRouter';
import AutoLogin from './autologin';
import { useHotkeys } from 'react-hotkeys-hook';
const useGlobalHotkeys = () => {

  const navigate = useNavigate();
  const location = useLocation();

  useHotkeys('alt+i', () => {
    // invoice page
    navigate('/invoices');
  }, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] });

  useHotkeys('alt+p', () => {
    // estimate page
    navigate('/purchase-bills');
  }
    , { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] });

    // alt+d
    useHotkeys('alt+d', () => {
      // estimate page
      navigate('/dashboard');
    }, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] });

    // alt+s /settings
    useHotkeys('alt+s', () => {
      // estimate page
      navigate('/settings');
    }, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] });

    // alt+r /reports
    useHotkeys('alt+r', () => {
      // estimate page
      navigate('/reports');
    }, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] });
  useHotkeys('alt+n', () => {
    // check current route
    if(location.pathname == '/invoices'){
      navigate('/invoice-create')
    }else if(location.pathname == '/purchase-bills'){
      navigate('/bill-new')
    }
  }, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] });


  // Add more shortcuts as needed
};

function App() {
  const navigate = useNavigate();

  useGlobalHotkeys();
  
  const [user, setUser] = React.useState(null);
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = React.useState('light')
  console.log(theme)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (!localStorage.getItem('user')) {
      navigate('/login')

    } else {


      setUser(JSON.parse(localStorage.getItem('user')))
      var apiRoute = new ApiRoute();
      apiRoute.postResponseFromRoute('app_user_api_get_gst_setting',).then((res) => {
        if (res.status == 'success') {
          dispatch(setGstSetting(res.data))
        }

      })
      // navigate('/dashboard')
    }
  }, [])

  React.useEffect(() => {
    if (theme.mode == 'dark') {
      setThemeMode('dark-mode')
    } else {
      setThemeMode('light-mode')
    }
  }, [theme])
console.log(!window.location.pathname.startsWith('/autologin'))
  if (user && window.location.pathname.startsWith('/autologin') === false) {
    return (
      <>

        <div key="root-div" className={themeMode} >
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <MyDrawer />
            <Box
              component="main"
              style={{ marginTop: '35px' }}
              sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${240}px)` } }}
            >
              <div className="" key="rcontent-key">
                {/* <TopNav /> */}
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px',
                      }}
                    >
                      <CircularProgress />
                    </div>
                  }
                >
                  <Container >
                    <Routes>
                      {AccessControlledRouterLinks()}

                    </Routes>


                  </Container>

                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"

                  />
                </React.Suspense>
              </div>
            </Box>
          </Box>

        </div>
      </>
    );
  } 
  else if(window.location.pathname.startsWith('/autologin') === true){
    // setUser null
    alert('logging out')
    localStorage.removeItem('user')
    
    return (
      <>
        <Container >
          
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/autologin/:bookGuid/:guid' element={<AutoLogin />} />
            {/* <Route path='/register' element={<Register />} /> */}
          </Routes>
        </Container>
      </>
    )
  }
  else {
    return (
      <>
        <Container >
          
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/autologin/:bookGuid/:guid' element={<AutoLogin />} />
            {/* <Route path='/register' element={<Register />} /> */}


          </Routes>
        </Container>
      </>
    )
  }

}

export default App;
