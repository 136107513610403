import { createSlice } from '@reduxjs/toolkit'

export const userInfoSlice = createSlice({
    name: 'rootUserInfo',
    initialState: {value: null},
    reducers: {
        setUserInfo: (state, data) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = data.payload;
        },
    }
})

// Action creators are generated for each case reducer function
export const { setUserInfo } = userInfoSlice.actions

export default userInfoSlice.reducer