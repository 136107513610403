import React from 'react'
import ApiRoute from './Router/ApiRouter';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGstSetting } from './reduxStores/slices/gstSettingSlice';

export default function AutoLogin() {
    const { bookGuid } = useParams();
    const { guid } = useParams();
    const dispatch = useDispatch();
    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            window.location.href = '/dashboard';
        } else {
            var apiRoute = new ApiRoute();
            apiRoute.postResponseFromRoute('app_public_api_autologin', {
                bookGuid: bookGuid,
                guid: guid
            }).then((response) => {
                if (response.status == 'success') {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    var apiRoute = new ApiRoute();
                    apiRoute.postResponseFromRoute('app_user_api_get_gst_setting',).then((res) => {
                        if (res.status == 'success') {
                            dispatch(setGstSetting(res.data))
                        }

                    })
                    window.location.href = '/dashboard';
                }
            }).catch((error) => {
                console.log(error);
            });
        }

    }, [])
    return (
        <div>sdd</div>
    )
}
