import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from './pages/login'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './reduxStores/store'
import { DarkThemeToggle, Flowbite } from 'flowbite-react';
import { CustomFlowbiteTheme } from "flowbite-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
const user = JSON.parse(localStorage.getItem('user'))
const customTheme =
{
  input: {
    border: "1px solid black"
  }
};
root.render(
  <React.StrictMode>

    <Provider store={store}>
      <Flowbite theme={{
        theme: customTheme,
      }}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Flowbite>
    </Provider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
