import { useState } from 'react';
import { Navigate } from 'react-router-dom';
// import NoInternetConnection from './NoInternetConnection'; // Import your NoInternetConnection component

class ApiRoute {
    constructor() {
        this.host = null;
        this.routes = {
            "app_user_userinfo": {
                "path": "/app/user/api/userinfo.json",
                "authenticate": true
            },
            "app_user_item_create": {
                "path": "/app/user/api/item/create.json",
                "authenticate": true
            },
            "app_user_authenticate": {
                "path": "/app/public/authenticate.json",
                // "authenticate":false
            },
            "app_public_get_currencies": {
                "path": "/app/public/get/currencies"
            },
            "app_user_customer_create": {
                "path": "/app/user/api/customer/create.json",
                "authenticate": true
            },
            "app_user_api_items_list": {
                "path": "/app/user/api/items/list.json",
                "authenticate": true
            },
            "app_user_item_list": {
                "path": "/app/user/api/items/list.json",
                "authenticate": true
            },
            "app_user_customer_list_json": {
                "path": "/app/user/api/customer/list.json",
                "authenticate": true
            },
            "app_ledger_list": {
                "path": "/app/user/api/ledger/list.json",
                "authenticate": true
            },
            "app_user_account_create": {
                "path": "/app/user/api/account/create.json",
                "authenticate": true
            },
            "app_user_ledger_type_select": {
                "path": "/app/user/api/ledger/type/select.json",
                "authenticate": true
            },
            "app_user_units_select": {
                "path": "/app/user/api/units.json",
                "authenticate": true
            },
            "app_user_api_invoice_list": {
                "path": "/app/user/api/invoice/index.json",
                "authenticate": true
            },
            "app_user_api_getcustomerselect": {
                "path": "/app/user/api/getcustomerselect.json",
                "authenticate": true
            },
            "app_user_api_invoice_create": {
                "path": "/app/user/api/invoice/create.json",
                "authenticate": true
            },
            "app_user_api_itemsearch": {
                "path": "/app/user/api/itemsearch.json",
                "authenticate": true
            },
            "app_user_api_getsalesaccountelect": {
                "path": "/app/user/api/get/sales/account/select.json",
                "authenticate": true
            },
            "app_user_api_get_item_detail": {
                "path": "/app/user/api/get/item/detail",
                "authenticate": true
            },
            "app_user_api_account_one": {
                "path": "/app/user/api/account/one.json",
                "authenticate": true
            },
            "app_user_api_invoice_one": {
                "path": "/app/user/api/invoice/one.json",
                "authenticate": true
            },
            "app_user_api_payment_recieved_list": {
                "path": "/app/user/api/payment/recieved/list.json",
                "authenticate": true
            },
            "app_user_api_getdepositedtoselect": {
                "path": "/app/user/api/get/depositedto/select.json",
                "authenticate": true
            },
            "app_user_api_payment_recieved_create": {
                "path": "/app/user/api/payment/recieved/create.json",
                "authenticate": true
            },
            "app_user_api_get_gst_setting": {
                "path": "/app/user/api/get/gst/setting.json",
                "authenticate": true
            },
            "app_user_api_save_gst_setting": {
                "path": "/app/user/api/save/gst/setting.json",
                "authenticate": true
            },
            "app_user_api_get_expense_account_select": {
                "path": "/app/user/api/get/expense/account/select.json",
                "authenticate": true
            },
            "app_user_api_get_paid_through_select": {
                "path": "/app/user/api/get/paid/through/select.json",
                "authenticate": true
            },
            "app_user_api_create_expense": {
                "path": "/app/user/api/create/expense.json",
                "authenticate": true
            },
            "app_user_api_dashboard": {
                "path": "/app/user/api/dashboard.json",
                "authenticate": true
            },
            "app_user_expense_list_json": {
                "path": "/app/user/api/expense/list.json",
                "authenticate": true
            },
            "app_user_api_bank_create": {
                "path": "/app/user/api/bank/create.json",
                "authenticate": true
            },
            "app_user_api_gst_verification": {
                "path": "/app/user/api/gst/verification",
                "authenticate": true
            },
            "app_user_api_profitandloss_report": {
                "path": "/app/user/api/profitandloss/report.json",
                "authenticate": true
            },
            "app_user_api_balancesheet_report": {
                "path": "/app/user/api/balancesheet/report.json",
                "authenticate": true
            },
            "app_user_api_banking_list": {
                "path": "/app/user/api/banking/list.json",
                "authenticate": true
            },
            "app_user_api_account_setting": {
                "path": "/app/user/api/account/setting.json",
                "authenticate": true
            },
            "app_user_api_get_gst_rate": {
                "path": "/app/user/api/get/gst/rate.json",
                "authenticate": true

            },
            "app_public_getGstCustomerType": {
                "path": "/app/public/api/get/custmer/types.json",
                "authenticate": false
            },
            "app_public_getplaceofsupply": {
                "path": "/app/public/api/get/placeofsupply.json",
                "authenticate": false
            },
            "app_user_api_item_one": {
                "path": "/app/user/api/item/one.json",
                "authenticate": true

            },
            "app_user_item_update": {
                "path": "/app/user/api/item/update.json",
                "authenticate": true

            },
            "app_user_api_invoice_setting_update": {
                "path": "/app/user/api/invoice/setting/update.json",
                "authenticate": true
            },
            "app_user_api_invoice_setting_get": {
                "path": "/app/user/api/invoice/setting/get.json",
                "authenticate": true
            },
            "app_user_api_payment_setting_update": {
                "path": "/app/user/api/payment/setting/update.json",
                "authenticate": true
            },
            "app_user_api_payment_setting_get": {
                "path": "/app/user/api/payment/setting/get.json",
                "authenticate": true
            },
            "app_user_api_invoice_number_get": {
                "path": "/app/user/api/invoice/number/get.json",
                "authenticate": true

            },
            "app_user_api_get_payment_id": {
                "path": "/app/user/api/getpaymentid.json",
                "authenticate": true
            },
            "app_user_api_invoice_html": {
                "path": "/app/user/api/invoice/html.json",
                "authenticate": true
            },
            "app_user_api_bill_create": {
                "path": "/app/user/api/bill/create.json",
                "authenticate": true
            },
            "app_user_api_bill_list": {
                "path": "/app/user/api/bill/list.json",
                "authenticate": true
            },
            "app_user_api_bill_one": {
                "path": "/app/user/api/bill/one.json",
                "authenticate": true
            },
            "app_user_api_bill_html": {
                "path": "/app/user/api/bill/html.json",
                "authenticate": true
            },
            "app_user_api_getpurchaseaccountelect": {
                "path": "/app/user/api/getpurchaseaccountselect.json",
                "authenticate": true
            },
            "app_user_api_gstrates": {
                "path": "/app/user/api/gstrates.json",
                "authenticate": true
            },
            "app_user_api_save_gst_rate": {
                "path": "/app/user/api/save/gst/rate.json",
                "authenticate": true

            },
            "app_public_api_autologin": {
                "path": "/app/public/api/autologin.json",
                "authenticate": false


            },
            "app_user_api_payment_made_list": {
                "path": "/app/user/api/payment/made/list.json",
                "authenticate": true
            },
            "app_user_api_get_paidthroghselect":{
                "path": "/app/user/api/get/paidthroghselect",
                "authenticate": true
            
            },
            "app_user_api_payment_made_create":{
                "path": "/app/user/api/payment/made/create.json",
                "authenticate": true
            },
            "app_user_api_sale_report_by_customer":{
                "path":"/app/user/api/sale/reportbycustomer",
                "authenticate":true
            },
            "app_user_api_gst_filing":{
                "path":"/app/user/api/gst/filing",
                "authenticate":true
            },
            "app_user_api_save_gst_filing":{
                "path":"/app/user/api/save/gst/filing",
                "authenticate":true
            },
            "app_user_api_credit_note_list":{
                "path":"/app/user/api/credit/note/list",
                "authenticate":true
            },
            "app_user_api_credit_note_setting_get":{
                "path":"/app/user/api/credit/note/setting/get.json",
                "authenticate":true
            },
            "app_user_api_credit_note_number_get":{
                "path":"/app/user/api/credit/note/number/get.json",
                "authenticate":true
            },
            "app_user_api_credit_note_create":{
                "path":"/app/user/api/credit/note/create.json",
                "authenticate":true
            },
            "app_user_api_vendor_list":{
                "path":"/app/user/api/vendor/list",
                "authenticate":true
            },
            
            "app_user_api_gst_filing_one_json":{
                "path":"/app/user/api/gst/filing/one.json",
                "authenticate":true
            },
            // app_user_api_gstr1_json
            "app_user_api_gstr1_json":{
                "path":"/app/user/api/gst/filing/gstr1.json",
                "authenticate":true
            },
            // gstr3b
            "app_user_api_gstr3b_json":{
                "path":"/app/user/api/gst/filing/gstr3b.json",
                "authenticate":true
            },
            "app_user_api_get_tax_preference":{
                "path":"/app/user/api/get/tax/preference",
                "authenticate":true
            },
            "app_user_api_ledger_accounts":{
                "path":"/app/user/api/ledger/accounts.json",
                "authenticate":true
            },
            "app_user_api_inventory_setting_get":{
                "path":"/app/user/api/inventory/setting/get.json",
                "authenticate":true
            },
            "app_user_api_inventory_setting_save":{
                "path":"/app/user/api/inventory/setting/save.json",
                "authenticate":true
            },
            "app_user_api_get_tax_options":{
                "path":"/app/user/api/get/tax/options.json",
                "authenticate":true
            },
            "app_user_ledger_select_by_type":{
                "path":"/app/user/api/ledgerselectbytype.json",
                "authenticate":true
            },
            "app_user_api_inventory_account":{
                "path":"/app/user/api/inventory/account.json",
                "authenticate":true
            },
            "app_user_api_customer_one":{
                "path":"/app/user/api/customer/one.json",
                "authenticate":true
            },
            "app_user_customer_update":{
                "path":"/app/user/api/customer/update.json",
                "authenticate":true
            },

            "app_user_api_vendor_one":{
                "path":"/app/user/api/vendor/one.json",
                "authenticate":true
            },
            "app_user_vendor_update":{
                "path":"/app/user/api/vendor/update.json",
                "authenticate":true
            },
            "app_user_api_item_setting_save":{
                "path":"/app/user/api/item/setting/save.json",
                "authenticate":true
            },
            "app_user_api_item_setting_get":{
                "path":"/app/user/api/item/setting/get.json",
                "authenticate":true
            },
            "app_user_api_item_image_upload":{
                "path":"/app/user/api/item/image/upload.json",
                "authenticate":true
            },
            'app_user_api_get_voucher_types':{
                "path":"/app/user/api/get/voucher/types.json",
                "authenticate":true
            },
            "app_user_api_get_accounts":{
                "path": "/app/user/api/get/accounts.json",
                "authenticate":true
            },
            "app_user_api_create_manual_journal":{
                "path":"/app/user/api/create/manual/journal.json",
                "authenticate":true
            },
            "app_user_api_manual_journal_list":{
                "path":"/app/user/api/manual_journal_list",
                "authenticate":true
            },
            "app_user_api_currency_select":{
                "path":"/app/user/api/currency/select",
                "authenticate":true
            },
            "app_user_api_sale_order_number_setting_get":{
                "path":"/app/user/api/sale/order_number_setting_get.json",
                "authenticate": true
            },
            "app_user_api_placeOfSupply_get":{
                "path":"/app/user/api/placeOfSupply_get.json",
                "authenticate": true
            },
            "app_user_api_sale_order_create":{
                "path": "/app/user/api/sale/order/create.json",
                "authenticate": true
            },
            "app_user_api_sale_order_list":{
                "path":"/app/user/api/sale/order/list.json",
                "authenticate": true
            }
            
            
        };
    }

    getRoutes() {
        return this.routes;
    }

    getCompleteRoutes() {
        const hostRoutes = {};
        Object.entries(this.routes).forEach(([key, value]) => {
            hostRoutes[key] = this.getHost() + value.path;
        });
        return hostRoutes;
    }

    getPath(route) {
        return this.getHost() + this.routes[route].path;
    }

    async postResponseFromRouteForImage(route, body = null, getParams = null) {
        // const result = await fetch('https://biobt.sarabit.com'); // Check internet connection here
        // if (!result.ok) {
        //   return false; // Navigate to NoInternetConnection component
        // }

        let url = this.getHost() + this.routes[route].path;
        let headers = {};

        if (this.routes[route].authenticate) {
            const authKey = await this.getAuthKey();
            headers = {
                'X-AUTH-TOKEN': authKey,
            };
        }
        if (getParams !== null) {
            url = url + getParams;
        }

        const response = await fetch(url, {
            method: 'POST',
            // mode: 'no-cors',
            headers,
            body: body !== null ? body : null,
        });

        var res = await response.json();

        if (response.status === 401) {
            // Handle unauthorized
            localStorage.removeItem('user');
            Navigate('/login')
            // redirect to the login page and also remove the user info from local storage
        }
        // console.log(response);
        return res;
    }
    async postResponseFromRoute(route, body = null, getParams = null) {
        // const result = await fetch('https://biobt.sarabit.com'); // Check internet connection here
        // if (!result.ok) {
        //   return false; // Navigate to NoInternetConnection component
        // }

        let url = this.getHost() + this.routes[route].path;
        let headers = {};

        if (this.routes[route].authenticate) {
            const authKey = await this.getAuthKey();
            headers = {
                'X-AUTH-TOKEN': authKey,
            };
        }
        if (getParams !== null) {
            url = url + getParams;
        }

        const response = await fetch(url, {
            method: 'POST',
            // mode: 'no-cors',
            headers,
            body: body !== null ? JSON.stringify(body) : null,
        });

        var res = await response.json();

        if (response.status === 401) {
            // Handle unauthorized
            localStorage.removeItem('user');
            Navigate('/login')
            // redirect to the login page and also remove the user info from local storage
        }
        // console.log(response);
        return res;
    }
    async getAuthHeaders() {
        const authKey = await this.getAuthKey();
        return {
            'X-AUTH-TOKEN': authKey,
        };
    }

    async getAuthKey() {
        // Implement your logic to get the authentication key
        // return 'your-auth-key';
        var userJson = localStorage.getItem('user');
        var user = JSON.parse(userJson);
        return user.authKey;
    }

    getHost() {
        // return 'https://api.accounts.sarabit.com';
        if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'){
            return 'https://localhost:8000';
        }else{
            return 'https://api.accounts.sarabit.com';
        
        }
        return 'https://localhost:8000';
    }
    getHostHttp() {
        // return 'http://api.accounts.sarabit.com';
        return 'https://localhost:8000';
    }
}

export default ApiRoute;
